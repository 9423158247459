<template>
  <div>
    <template>
      <v-data-table
        :headers="headers"
        :items="learner_list"
        class="elevation-1"
          :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Application Received List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
         
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <download-excel
              class="mar"
              v-if="learner_list.length != 0"
              color="primary"
              dark
              :data="learner_list"
              :fields="table_fields"
              worksheet="My Worksheet"
              name="Application Recevied Report.xls"
            >
              <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>
             <!-- <v-btn v-if="learner_list.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn> -->
            <span style="width: 20px"></span>
          </v-toolbar>
        </template>
      </v-data-table>

                 <!--simple table for excel start-->

               <!-- <v-simple-table id="exceltable"  class="elevation-1 tableexcel" :search="search" v-if="learner_list.length!=0">
       
                  <template v-slot:default>
                    <thead>
                      <tr>
                         <th class="text-left"> Sr. No. </th> -->
                      <!--   <th class="text-left">Registration Date </th>
                        <th class="text-left"> Company Name</th>
                        <th class="text-left"> Laerner Reg No</th>
                        <th class="text-left"> Name</th>
                        <th class="text-left"> Program</th>
                        <th class="text-left"> Department</th>
                        <th class="text-left"> Year</th>-->
                        <!-- <th class="text-left"> Questions</th> 
                        <th class="text-left"> Academic details</th>
                      </tr>
                      
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in learner_list"
                        :key="item.id"
                      >-->
                        <!-- <td>{{ item.srno }}</td> -->
                      <!--  <td>{{ item.registration_date }}</td>
                        <td>{{ item.comapny_name }}</td>
                        <td>{{ item.learner_grno }}</td>
                        <td>{{ item.learner_name }}</td>
                        <td>{{ item.learner_program }}</td>
                        <td>{{ item.learner_department }}</td>
                        <td>{{ item.learner_year }}</td>
                        <td>
                            <span v-for="it in item.questions">
                                    {{it.question}} : 
                                    {{it.answer}}<br>
                            </span>
                        </td>
                        <td>
                            <span v-for="it in item.academic_details">
                                    {{it.degree}} : {{it.marks }} <br>
                                    
                            </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table> -->
       <!--simple table for excel end-->




    </template>
  </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
export default {
  data() {
    return {
      learner_list: [],
      search:"",
      headers: [
        {
          text: "Comapny Name",
          align: "start",
          sortable: true,
          value: "comapny_name",
        },
        { text: "Registration Date", value: "registration_date" },
        { text: "Name", value: "learner_name" },
        { text: "Gr No", value: "learner_grno" },
        { text: "Program", value: "learner_program" },
        { text: "Department", value: "learner_department" },
        { text: "Year", value: "learner_year" },
        // { text: "Questions", value: "questions" },
      ],
      table_fields: {
      "Comapny Name": "comapny_name",
      "Registration Date": "registration_date",
      Name: "learner_name",
      "Gr No": "learner_grno",
      Program: "learner_program",
      Department: "learner_department",
      Year: "learner_year",
      // Questions:"questions"
      
    },
    
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    onload() {
      const data = {
        offering: this.scheduling_id,
      };
      //console.log("a");
      axios
        .post("/OnCampusProcess/getAppliedstudentlist", data)
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.learner_list = res.data.comp_off_learner_list;
            //console.log("res.data");
            
            //console.log(this.learner_list);
            //  this.headers.push(temp);
            var index = 0;
            var tempsize= this.learner_list[0].academic_details.length;
              for (var j in this.learner_list) {
              //  var tempsize = this.learner_list[j].academic_details.length;
                 if(tempsize < this.learner_list[j].academic_details.length){
                   tempsize = this.learner_list[j].academic_details.length
                   index=j;
                 }
              }
              for(var i in  this.learner_list[index].academic_details)
              {
                  var temp = {
                  text: this.learner_list[index].academic_details[i].degree,
                  value: this.learner_list[index].academic_details[i].degree,
                }
                this.headers.push(temp);
                this.table_fields[this.learner_list[index].academic_details[i].degree]= this.learner_list[index].academic_details[i].degree;
              }





              for(var i in  this.learner_list[index].questions)
              {
                //console.log("this.learner_list[index].questions[i]");
                //console.log(this.learner_list[index].questions[i]);
                  var temp = {
                  text: this.learner_list[index].questions[i].question,
                  value: this.learner_list[index].questions[i].question,
                }
                this.headers.push(temp);
                 this.table_fields[this.learner_list[index].questions[i].question]= this.learner_list[index].questions[i].question;
              }
          } else {
            //error
            console.log("error");
            //console.log(res);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },

     exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Applications_Received", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
  },
  mounted() {
    this.scheduling_id = this.$route.params.scheduling_id;
    //console.log("this.scheduling_id");
    //console.log(this.scheduling_id);
    this.onload();
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
.tableexcel{
display:none;
}

</style>